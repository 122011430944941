<template>
    <div class="icon-container">
      <div :id="id">
        <slot></slot>
      </div>
      <b-tooltip v-if="tooltip" :target="id" triggers="hover" :placement="placement || 'bottom'">
        {{ tooltip }}
      </b-tooltip>
    </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'Icon',
  props: ['tooltip', 'placement'],
  data() {
    return {
      id: `icon_${uuidv4()}`
    }
  },
}
</script>
<style lang="scss" scoped>
  .icon-container {
    display: flex;
  }
</style>
