<template>
  <div>
    <label>
      <strong>Logo do convênio</strong>
    </label>
    <div class="image-container">
      <div class="edit-wrapper" @click="$refs.FileInput.click()">
        <Icon tooltip="Editar imagem">
          <Edit class="icon" />
        </Icon>
      </div>

      <img
        v-if="image.cropedImage || logo"
        class="logo-image"
        :src="image.cropedImage || logo"
      />

      <input
        ref="FileInput"
        type="file"
        style="display: none;"
        accept="image/png"
        @change="onFileSelect"
      />

      <b-modal id="crop-modal" hide-header hide-footer @hidden="resetCrop">
        <VueCropper
          ref="cropper"
          v-show="image.selectedFile"
          :src="image.selectedFile"
          :outputSize="maxImageSize"
          :aspectRatio="4 / 2"
          :cropBoxResizable="true"
        ></VueCropper>

        <div class="mt-2 text-right">
          <b-button variant="primary" @click="cropImage"
            >Recortar imagem</b-button
          >
        </div>
      </b-modal>    
    </div>
    <div class="mt-2">
      <p class="help">
        Envie um arquivo nos formatos .jpg ou .png. Com tamanho máximo de {{maxImageSize}}kb.
      </p>
    </div>
  </div>
</template>
<script>
import Icon from '@/components/General/Icon'
import Edit from '@/assets/icons/edit.svg'
export default {
  components: { Icon, Edit },
  props: {
    logo: String,
  },
  data() {
    return {
      maxImageSize: 400,
      image: {
        selectedFile: '',
        cropedImage: '',
        cropedBlob: ''
      },
    }
  },
  methods: {
    onFileSelect(e) {
      const file = e.target.files[0]

      if ((file.size * 8 / (8 * 1000)) > this.maxImageSize) {
        this.$toast.warning(`A imagem deve ter no máximo ${this.maxImageSize}kb`);
        return;
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.image.selectedFile = event.target.result
          this.$refs.cropper.replace(this.image.selectedFile)
        }
        reader.readAsDataURL(file)
        this.$bvModal.show('crop-modal')
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    resetCrop() {
      this.image.selectedFile = ''
    },
    async cropImage() {
      this.image.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.$refs.cropper.getCroppedCanvas().toBlob(blob => {
        this.image.cropedBlob = blob
      });
      this.$emit('changeFile', this.image.cropedImage || null)
      this.$bvModal.hide('crop-modal')
    },
  }
}
</script>
<style lang="scss" scoped>
.image-container {
  position: relative;
  width: 240px;
  height: 120px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--type-active);
  background: var(--neutral-200);
}
.edit-wrapper {
  position: absolute;
  top: -5px;
  right: -5px;
  padding: 5px 8px;
  border-radius: 30px;
  background-color: var(--blue-500);
  cursor: pointer;
}
.icon {
  width: 20px;
  height: 20px;
  stroke: white;
}
.logo-image {
  width: 240px;
  height: 120px;
  border-radius: 8px;
}
.help {
  font-weight: 600;
  font-size: 12px;
  color: var(--type-placeholder);
  padding: 12px 1px;
}
</style>
